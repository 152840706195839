$(document).ready(function() {
	colorpicker.init();
});

var colorpicker = (function() {
	var colorInput = $('#colorpicker');
	return {
		init: function() {
        	colorInput.colorpicker();
        	$('.evo-colorind').on('click', function () {
                colorInput.prop("checked", true);
                $(this).css("background-color", "white");
        	})
		}
	}
}());