$(document).ready(function () {
    var locationsQuantity = $(".location__quantity");
    locationsQuantity.each(function (index, item) {
        $(item).animateNumber({ number: $(item).attr("data-user-count"), easing: "ease-in" }, 1200)
    });

    $(".search__select-language").val($(".search__select-language").data("chosenlanguage")).trigger("change");

    $(".location__wifi-pass").on("click", function (e) {
        e.stopImmediatePropagation();
        $(this).hide();
        var input = $(this).next(".location-pass-change");
        input.show();
    });

    $(".location-change-pass-save").on("click", function (e) {
        e.stopImmediatePropagation();
        var prev = $(this).prev("input");
        var newPass = $(prev).val();
        var location = prev.data("locationid");
        $(this).parent().hide();
        $(this).parent().prev(".location__wifi-pass").show();
        $(this).parent().prev(".location__wifi-pass").html("...");

        $.ajax({
            url: "/backend/changewifipass",
            dataType: "json",
            type: "GET",
            data: { password: newPass, locationId: location }
        }).done(function (data) {
            $(this).parent().prev(".location__wifi-pass").html(newPass);
        });
    });

    $(".private__checkbox #checkbox").on("change", function (e) {
        $.ajax({
            url: "/backend/enableprivatewifi",
            dataType: "json",
            type: "GET",
            data: { disabled: e.target.checked, locationId: $(this).data("locationid") }
        }).done(function (data) {
        });
    });

    $(".settings__submit").on("click", function ($e) {
        var eventRaiser = $e.target;
        var listItem = $(eventRaiser).parent().parent();
        listItem.hide();
        listItem.next(".router-update-field").css("display", "block");
    });

    $(".change-wifi-password").on("click", function (e) {
        var eventRaiser = $e.target;
        var pass = $(eventRaiser).prev("input").val();
        var locationid = $(this).data("locationid");
        $.ajax({
            url: "/backend/changewifipass",
            dataType: "json",
            type: "GET",
            data: { password: pass, locationId: locationid }
        }).done(function (data) {
            $(eventRaiser).parent().parent().hide();
            $(".settings__edit--password").show();
        });
    });

    $(".change-network-name").on("click", function ($e) {
        var eventRaiser = $e.target;
        var ssid = $(eventRaiser).prev("input").val();
        var locationid = $(this).data("locationid");
        $.ajax({
            url: "/backend/changewifissid",
            dataType: "json",
            type: "GET",
            data: { ssid: ssid, locationId: locationid }
        }).done(function (data) {
            $(eventRaiser).parent().parent().hide();
            $(".settings__edit--name").show();
        });
    });

    $(".time__select").on("change", function ($e) {

        if ($('.time__select').val() !== $("#sessionTime").val()) {
            var locationid = $(this).data("locationid");
            $.ajax({
                url: "/backend/changesessiontime",
                dataType: "json",
                type: "GET",
                data: { sessiontime: this.value, locationId: locationid }
            }).done(function (data) {
                $(eventRaiser).parent().parent().hide();
                $(".settings__edit--name").show();
            });
        }
    });

    $(".locations__item").on("click", function ($e) {
        var locationid = $(this).data("locationid");

        $.ajax({
            url: "/portal/splash",
            dataType: "json",
            type: "GET",
            data: { locationid: locationid }
        }).done(function (data) {
        });
    });

    $(".activate-plan").on("click", function ($e) {
        var planTitle = $(this).parent().data("plantitle");

        $.ajax({
            url: "/portal/settings/activatePlan",
            dataType: "json",
            type: "GET",
            data: { planTitle: planTitle }
        }).done(function (data) {
            location.reload();
        });
    });

    $(".search__select-language").on("change", function ($e) {
        if ($('.search__select-language').val() !== $(".search__select-language").data("chosenlanguage")) {
            var chosenLanguage = $('.search__select-language').val();
            $.ajax({
                url: "/portal/settings/changeLanguage",
                dataType: "json",
                type: "GET",
                data: { chosenLanguage: chosenLanguage }
            }).done(function (data) {
            });
        }
    });

    $(".settings-delete.user").on("click", function ($e) {
        var userId = $(this).data("user");
        $.ajax({
            url: "/portal/settings/deleteUser",
            dataType: "json",
            type: "POST",
            data: { userId: user }
        }).done(function (data) {
        });
    });

    $(".settings-edit-user").on("click", function ($e) {
        $('.add-new-user-container').hide();
        $('.edit-user-container').show();
        $('.edit-user-id').attr("value", $(this).data("user"));
        $(this).closest('li.users__item').css("background-color", "#eb6268");
        $(this).closest('li.users__item').css("color", "white");
    });

    $(".settings-edit-password").on("click", function ($e) {
        $('.add-new-user-container').hide();
        $('.edit-user-container').hide();
        $('.edit-pass-container').show();
        $('.edit-user-id').attr("value", $(this).data("user"));
        $(this).closest('li.users__item').css("background-color", "#eb6268");
        $(this).closest('li.users__item').css("color", "white");
    });
});
