$(document).ready(function() {
    timeSelect.init();

    if ($("#sessionTime").val() != "") {
        $('.time__select').val($("#sessionTime").val()).trigger("change");
    }
});

var timeSelect = (function() {
	var select = $('.time__select');
	var data = ['30m', '1h', '2h', '3h', '4h', '6h', '8h', '10h', '12h', '14h'];


	return {
		init: function() {
			select.select2({
				data: data,
				minimumResultsForSearch: -1,
				allowClear: false
			});

			select.next('.select2').mouseenter(function() {
			    select.select2('open');

				var selectContainer = $('.select2-container');
				var selectDropdown = $('.select2-dropdown--below');
				var selectItem = $('.select2-results__option');
				var selectedItem = $('.select2-results__option--highlighted');
				var selectedItemIndex = selectItem.index( selectedItem ) + 1;
				
				selectContainer.addClass('select-router-time');
				selectDropdown.css('top', '-' + 27 * selectedItemIndex + 'px');

			});

			$(document).on("mouseleave", ".select2-container", function(e) {
			    if ($(e.toElement || e.relatedTarget).closest(".select2-container").length == 0) {
			        select.select2("close");
			    }    
			})

		}
	}
}());