var splashScreen = (function () {
    var splashScreen = $('.splash-main-screen');
    var splashScreenWrapper = $('.splash-main-screen-wrapper');
    var rateModal = $('.rate-modal');
    var tripModal = $('.trip-modal');
    var menuButton = $('.signon-menu__button');

    var flipAvatar = function () {
        var flipContainer = $('.flip-container');
        setInterval(function () {
            if (flipContainer.hasClass('flip')) {
                flipContainer.removeClass('flip');
            } else {
                flipContainer.addClass('flip');
            }
        }, 2 * 1000);

    };
    var showIntroMessage = function () {
        setTimeout(function () {
            //var introMessage = 'Dobrodošli u';
            var message = $('<p class="typing intro-message">' + introMessage + ' ' + locationName + '</p>');
            $('.title').append(message);
            slideSplashModalScreen();
        }, 0 * 1000);
    };

    var slideSplashModalScreen = function () {
        setTimeout(function () {
            var bodyWrapper = $('body');
            var divider = $('.divider');
            var icons = $('.icons-list li');
            // Enable scroll
            // This should be handled differently.
            //bodyWrapper.css('overflow-y', 'scroll');
            // Set header border color
            divider.css('background-color', themeColor);
            // Set initial background image
            var heightValue = $(window).height();

            if (backgroundImage) {
                splashScreen.css('background-image', 'url(' + backgroundImage + ')');
                splashScreen.css('background-size', 'cover');
                splashScreen.css('background-position', 'center');
            }
            else {
                splashScreen.addClass('join-background');
            }

            splashScreen.css('height', heightValue);
            splashScreen.css('min-height', heightValue);
            // Slide screen from let to right side
            splashScreenWrapper.addClass('slide-right');
            icons.addClass('fadeIn-icons');
            // Change text color for intro messages
            $('.title').addClass('header-title');
            if (submitButton) {
                splashScreen.append('<a class="next-button" target="_blank">' + submitButton + '<i class="icomoon-go1"></i></a>');
            }
            $(".next-button").attr("href", nextButtonHref);
            createMouseIcon();
        }, 0 * 1000);
    };

    var createMouseIcon = function () {
        setTimeout(function () {
            splashScreen.append('<div class="scroll-icon-wrapper"><div class="scroll-wrapper"><i class="icon-mouse"></i></div><div class="scroll-wrapper bounce"><i class="icon-arrow"></i></div></div>');
        }, 0*1000);
    };

    var lastScrollTop = 0;

    var buttonsManipulation = function () {
        var scrollArrow = $('.scroll-icon-wrapper .scroll-wrapper');
        var nextButton = $('.next-button');
        var st = $(this).scrollTop();
        if (st === 0) {
            if (submitButton) {
                nextButton.html(submitButton + '<i class="icomoon-go1"></i>');
            }
            nextButton.removeClass('circle-nextButton');
        }
        else {
            // Remove text from the button and make it look like circle
            nextButton.text('');
            nextButton.html('<i class="icomoon-go1"></i>');
            nextButton.addClass('circle-nextButton');
            // Remove bounce effect on arrow when user scroll down
            scrollArrow.removeClass('bounce');
        }
        lastScrollTop = st;
    };

    $("#fbLike").on("click", function () {
        $(".fb-like").click();
        if (themeColor) {
            if ($(this).hasClass("icon-activated")) {
                $(this).find("i").css('color', "#808080");
                $(this).find(".label").css('color', "#808080");
                $(this).removeClass("icon-activated");
            }
            else {
                $(this).find("i").css('color', themeColor);
                $(this).find(".label").css('color', themeColor);
                $(this).addClass("icon-activated");
            }

        }

        $.ajax({
            url: "/backend/social",
            type: "GET",
            data: {name: "fbLike", locationId: locationId, enduserId: userId}
        }).done(function (data) {
        });

    });

    $('#joinRate').click(function () {
        openRateModal();
    });

    $("#tripAdvisor").click(function () {
        openTripModal();
    });

    var rating = $("#join-rate-plugin").rateYo({
        ratedFill: themeColor,
        starWidth: "40px",
        fullStar: true
    });

    var isRated = function () {
        if (rating.rateYo("rating") > 0) {
            $("#joinRate").find("i").css('color', "#808080");
            $("#joinRate").find(".label").css('color', "#808080");
            $("#joinRate").addClass("icon-activated");
        }
        else {
            $("#joinRate").find("i").css('color', themeColor);
            $("#joinRate").find(".label").css('color', themeColor);
            $("#joinRate").removeClass("icon-activated");
        }
    }

    var setRate = rating.rateYo("rating", joinRate);

    $("#join-rate-plugin").on("click", function () {
        $.ajax({
            url: "/backend/rate",
            type: "GET",
            data: { locationId: locationId, enduserId: userId, rate: rating.rateYo("rating")},
            dataType: "json"
        }).done(function (data) {
            closeRateModal();
        });
    });

    $('.close-rate-modal').click(function() {
        closeRateModal();
    });

    $('.close-trip-modal').click(function () {
        closeTripModal();
    });

    function closeRateModal () {
        rateModal.removeClass('rateModalActive');
    }

    function closeTripModal() {
        tripModal.removeClass('tripModalActive');
    }

    function openModal(type) {
        modal.addClass('modalActive');
        menuButton.addClass('open');
        $('.rate-modal-content' + type).show();
    }

    function openRateModal() {
        rateModal.addClass('rateModalActive');
        menuButton.addClass('open');
    }

    function openTripModal() {
        tripModal.addClass('tripModalActive');
        menuButton.addClass('open');
    }


    // Detect scroll

    function showHideStickyMenu() {
        buttonsManipulation();
        var stickyMenu = $('.sticky-menu');
        if(splashScreenWrapper.hasClass('slide-right')) {
            stickyMenu.show();
        }
        stickyMenu.animate({'margin-left': '0'});
        $(this).off('scroll')[0].setTimeout(function(){
            stickyMenu.animate({'margin-left': '-85px'});
            $(this).on('scroll',showHideStickyMenu);
        }, 2500)
    }

    $(window).on('scroll', function() {
        showHideStickyMenu();
    });

    return {
        init: function () {
            flipAvatar();
            showIntroMessage();
        }
    }
}());

$(document).ready(function () {
    splashScreen.init();
});