$(document).ready(function() {
	signonMenu.init();
});

var signonMenu = (function() {
	var menuButton = $('.signon-menu__button');
	var subMenuButton = $('.signon-menu__link--trigger');
	var menu = $('.signon-menu');
	var subMenu = $('.signon-menu__sub-list');

	var toggleMenu = function(link) {
		link.on('click', function() {
			menuButton.toggleClass('open');
			menu.toggleClass('signon-menu--active');
		});
	}

	var toggleSubMenu = function(link) {
		link.on('click', function() {
			var thisMenu = $(this).next('.signon-menu__sub-list');

			if (!thisMenu.hasClass('signon-menu--active')) {
				subMenu.removeClass('signon-menu--active');
				thisMenu.addClass('signon-menu--active');
			} else {
				thisMenu.removeClass('signon-menu--active');
			}

		});
	}

	var closeMenu = function(e) {
		$(document).mouseup(function(e) {
			if (!menu.is(e.target) && menu.has(e.target).length === 0) {
				toggleMenu(menuButton);
				menu.removeClass('signon-menu--active');
				menuButton.removeClass('open');
				subMenu.removeClass('signon-menu--active');
			}
		});
	}

	return {
		init: function(e) {
			toggleMenu(menuButton);
			toggleSubMenu(subMenuButton);
			//closeMenu();
		}
	}

}());