$(document).ready(function () {
    signonScreens.init();
});

function hideMenu() {
    if ($(".signon__block--login-facebook").hasClass("signon__block--active")) {
        $(".signon-menu__button-wrapper").show();
        $(".signon__help").show();
    }
    else {
        $(".signon-menu__button-wrapper").hide();
    }
    
    if ($(".signon__block--login-others").hasClass("signon__block--active")) {
        
        $(".signon-menu__button-wrapper.return--button").show();
    }
    else {
        $(".signon-menu__button-wrapper.return--button").hide();
    }
}

var signonScreens = (function () {
    var signonWelcomeButton = $('#signonWelcomeButton');
    var signonFacebookButton = $('#signonFacebookButton');
    var signonNologinButton = $('#signonNologinButton');
    var signonWarningButton = $('#signonWarningButton');
    // added by atiq
	var signonNologinContinue = $('#signonNologinContinue');
	// end
    var returnButton = $(".signonReturnToPrevious");
    
    var screenChange = function (link) {
        link.on('click', function (e) {
            var signonBlockContainer = '.signon__block';
            var thisScreen = $(this).closest(signonBlockContainer);
            var nextScreen = thisScreen.next();
            var previousScreen = thisScreen.prev(signonBlockContainer);
            
            if ($(this).hasClass("signonReturnToPrevious")) {
                var facebookLogin = $(".signon__block--login-facebook");
                thisScreen = $(".signon__block--login-others");
                nextScreen = facebookLogin;
            }
            
            e.preventDefault();
            
            thisScreen.removeClass('signon__block--active');
            
            if (link != signonWarningButton) {
                nextScreen.addClass('signon__block--active');
            } else {
                signonFacebookButton.closest('.signon__block').addClass('signon__block--active');
            }
            
            hideMenu();
            setTimeout(function () {
                if ($(".signon__block--login-facebook").hasClass("signon__block--active")) {
                    $('.signon__help').fadeIn(150).fadeOut(400).fadeIn(150).fadeOut(400).fadeIn(150);
                }
                else {
                    $('.signon__help').hide();
                }
            }, 500);

        })
    }
    
    
    return {
        init: function () {
            screenChange(signonWelcomeButton);
            screenChange(signonFacebookButton);
            screenChange(signonNologinButton);
            screenChange(signonWarningButton);
            // added by atiq
			screenChange(signonNologinContinue);
			// end
            screenChange(returnButton);
            hideMenu();
        }
    }

}());



// Added by Atiq
// Conditional script for y'Continue' button fadeIn after selecting age and/or sex
$(document).ready(function() {
	continueToSplash.init();
});

var continueToSplash = (function() {
	//Taking the parent as parentSelector and all the Select element Selectors child Selector
  var parentSelector = $('#input-for-age-and-sex');
  var childSelector1 = $('#select-age');
  var childSelector2 = $('#select-sex');
	var buttonSelector = $('#submitButton');

  var showTheButton = function() {
	  var selectObject = $('#submitButton');
	  //selectObject.removeClass('hide-button').addClass('show-button');
		selectObject.fadeIn(1000);
		// console.log('Show the button called', selectObject);
	};


	//Event handler function for single select element
	var forSingleSelectElementChange = function(parentSelector, childSelector) {
	  $( parentSelector ).on( 'change', childSelector, function(e) {
	    e.preventDefault();
	    showTheButton();
	  });
	};


	//Event handler function for Multiple select element
	var forBothSelectElementChange = function(giveChildSelector1, giveChildSelector2) {

	  var isSelectElement1Changed = '';
	  var isSelectElement2Changed = '';


	  $( giveChildSelector1 ).on( 'change', function(e) {
	    e.preventDefault();
	    isSelectElement1Changed = true;
	    //console.log(isSelectElement1Changed);
	    (isSelectElement1Changed && isSelectElement2Changed) ? showTheButton() : '';
	  });


	  $( giveChildSelector2 ).on( 'change', function(e) {
	    e.preventDefault();
	    isSelectElement2Changed = true;
	    //console.log(isSelectElement2Changed);
	    (isSelectElement1Changed && isSelectElement2Changed) ? showTheButton() : '';
	  });

	};


	// Select Menu main Function
	var selectMainFuntion = function(parentSelector, childSelector1, childSelector2) {
		//select1ContainsNo contains the number of this given select element in the DOM
	  var select1ContainsNo = $(parentSelector).has(childSelector1).length;
	  var select2ContainsNo = $(parentSelector).has(childSelector2).length;
	  //bothSelectContains will check if the both select element exist in the DOM
	  var bothSelectContains = select1ContainsNo + select2ContainsNo;

	  if(select1ContainsNo === 0) {
	    forSingleSelectElementChange(parentSelector, childSelector2);
	  }
	  else if(select2ContainsNo === 0) {
	    forSingleSelectElementChange(parentSelector, childSelector1);
	  }
	  else if(bothSelectContains === 2) {
	    forBothSelectElementChange(childSelector1, childSelector2);
	  }

	  //console.log(bothSelectContains);
	};


	return {
		init: function() {
			selectMainFuntion(parentSelector, childSelector1, childSelector2);
		}
	}

}());

// end