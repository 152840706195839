$(document).ready(function() {
    splashPopups.init();


});

var splashPopups = (function() {

	var closeLink = $('.popup__close');

	var popupBackgrounds = $('.splash__popup--backgrounds');
	var linkBackgrounds = $('.splash-link--backgrounds');

	var popupTemplate = $('.splash__popup--template');
	var linkTemplate = $('.splash-theme__radio-label--custom');

	var popupPost = $('.splash__popup--post');
	var linkPost = $('.splash__add-link--post');

	var popupBanner = $('.splash__popup--banner');
	var linkBanner = $('.splash__add-link--banner');

	var popupTime = $('.splash__popup--time');
    var linkTime = $('.splash__add-link--time');

    var tripAdvisor = $('.splash__popup-trip-advisor');
    var linkTripAdvisor = $(".splash__add-link-trip-advisor");

    var popupCustomBackgrounds = $('.splash__popup-custom-backgrounds');
    var linkCustomBackgrounds = $(".splash__add-link-custom-backgrounds");

    var popupCustomLogos = $('.splash__popup-custom-logos');
    var linkCustomLogos = $('.splash__add-link-custom-logos');

    var popupCustomBanners = $(".splash__popup-custom-banners");
    var linkCustomBanners = $(".splash__add-link-custom-banners");

	var popup = function(link, popup) {
		link.click(function(e) {
			e.preventDefault();
			popup.css('left', '0');
		});

		closeLink.click(function(e) {
			e.preventDefault();
			popup.css('left', '-200%');
		});

	}

	return {
		init: function() {
			popup(linkBackgrounds, popupBackgrounds);
			popup(linkTemplate, popupTemplate);
			popup(linkPost, popupPost);
			popup(linkBanner, popupBanner);
            popup(linkTime, popupTime);
            popup(linkTripAdvisor, tripAdvisor);
            popup(linkCustomBackgrounds, popupCustomBackgrounds);
            popup(linkCustomLogos, popupCustomLogos);
            popup(linkCustomBanners, popupCustomBanners);
		}
	}
}());