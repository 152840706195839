$(document).ready(function () {
    splashToggle.init();
});

var splashToggle = (function () {

    var fbLink = $('.splash__toggle-fb');
    var fbContainer = $('.splash__toggled-fb');
    var fbCheckbox = $('#moduliFbLike');

    var fbCheckinLink = $(".splash__toggle-fb-checkin");
    var fbCheckinContainer = $(".splash__toggled-fb-checkin");
    var fbCheckinCheckbox = $("#moduliFbCheckin");

    var instagramLink = $(".splash__toggle-instagram");
    var instagramContainer = $(".splash__toggled-instagram");
    var instagramCheckbox = $("#moduliInstagram");    

    var videoLocationLink = $(".splash__video-location-link");
    var videoLocationContainer = $(".splash__toggled-video-locations");

    var locationListLink = $("#chooseLocationListItem");
    var locationListContainer = $(".location-list");

    var bannerLink = $(".splash__toggle-banners");
    var bannerContainer = $(".splash__toggled-banners");

    var toggle = function (link, container, checkbox, displayType) {
        link.click(function (e) {
            container.slideToggle('fast', function () {
                if ($(this).is(':visible')) {

                    if (displayType) {
                        $(this).css('display', displayType);
                    }
                    else {
                        $(this).css('display', 'inline-block');
                    }
                }
            });
            e.preventDefault();

            if (checkbox) {
                if (checkbox.attr('checked')) {
                    checkbox.attr('checked', false);
                }
                else {
                    checkbox.attr('checked', true);
                }
            }
            });
    }

    return {
        init: function () {
            toggle(fbLink, fbContainer, fbCheckbox);
            toggle(fbCheckinLink, fbCheckinContainer, fbCheckinCheckbox);
            toggle(instagramLink, instagramContainer, instagramCheckbox);
            toggle(videoLocationLink, videoLocationContainer, false, "list-item");
            toggle(bannerLink, bannerContainer);
        }
    }
} ());