$(document).ready(function() {
	menuDropdown.init();
});

var menuDropdown = (function() {
	var trigger = $('.menu__trigger');
	
	return {
		init: function(e) {
			trigger.on('click', function(e) {
				var menu = $(this).closest('.menu');
				var menuOther = $('.menu').not(menu);

				e.preventDefault();

				menuOther.removeClass('active');

				if (!menu.hasClass('active')) {
					menu.addClass('active');

					$(document).one('click', function closeMenu(e) {
						if (menu.has(e.target).length === 0 && $(trigger).has(e.target).length === 0) {
							menu.removeClass('active');
						} else if (menu.hasClass('active')) {
							$(document).one('click', closeMenu);
						}
					});
				} else {
					menu.removeClass('active');
				}


			});
		}
	}
}());