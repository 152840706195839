$(document).ready(function () {
    toastrInit.init();
});

var toastrInit = (function () {

    var messageType = {
        info: 0,
        warning: 1,
        success: 2
    }

    var currentlyNotAvailable = $(".toastr__not-available");   

    var show = function (link, message, messageType) {
        link.click(function (e) {
            switch (messageType) {
                case 0:
                    toastr.info(message);
                    break;
                case 1:
                    toastr.warning(message);
                    break;
                default:
                    toastr.success(message);
            } 
            });
    }

    return {
        init: function () {
            show(currentlyNotAvailable, "Trenutno nedostupna funkcionalnost", messageType.warning);
        }
    }
} ());