$(document).ready(function () {
    help.init();
});

var help = (function () {

    var templateTrigger = $('.toggle-help-template');
    var templateContent = $('.help-template-content');

    var themeTrigger = $(".toggle-help-theme");
    var themeContent = $(".help-theme-content");

    var logoTrigger = $(".toggle-help-logo");
    var logoContent = $(".help-logo-content")

    var backgroundTrigger = $(".toggle-help-background");
    var backgroundContent = $(".help-background-content")

    var colorsTrigger = $(".toggle-help-colors");
    var colorsContent = $(".help-colors-content");

    var socialsTrigger = $(".toggle-help-socials");
    var socialsContent = $(".help-socials-content");

    var managingTrigger = $(".toggle-help-managing");
    var managingContent = $(".help-managing-content");

    var fbFeedTrigger = $(".toggle-help-fb-feed");
    var fbFeedContent = $(".help-fb-feed-content");

    var instFeedTrigger = $(".toggle-help-inst-feed");
    var instFeedContent = $(".help-inst-feed-content");

    var bannerTrigger = $(".toggle-help-banner");
    var bannerContent = $(".help-banner-content");

    var helpSlide = function (trigger, content) {

        $(".icomoon-close2").click(function () {
            $(content).animate({
                right: '-50%'
            }, 500)
        });

        $(trigger).click(function () {

            if ((content).css("right") === '0px') {
                $(content).animate({
                    right: '-50%'
                }, 500)
            }
            else {
                $(content).animate({
                    right: '0'
                }, 500)
            };
        });
    }

    return {
        init: function () {
            helpSlide(templateTrigger, templateContent);
            helpSlide(themeTrigger, themeContent);
            helpSlide(logoTrigger, logoContent);
            helpSlide(backgroundTrigger, backgroundContent);
            helpSlide(colorsTrigger, colorsContent);
            helpSlide(socialsTrigger, socialsContent);
            helpSlide(managingTrigger, managingContent);
            helpSlide(fbFeedTrigger, fbFeedContent);
            helpSlide(instFeedTrigger, instFeedContent);
            helpSlide(bannerTrigger, bannerContent);
        }
    }
} ());