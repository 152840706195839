$(document).ready(function() {
	splashTabs.init();
});

var splashTabs = (function() {
	var tabsLink = $('.splash-tabs__link');
	var nextLink = $('.splash__next');

	var changeTab = function(link) {
		link.click(function(e) {
			e.preventDefault();

			var tabId = $(this).attr('data-tab');
			var tabLink = "[data-tab='" + tabId + "']";

			tabsLink.removeClass('current');
			$('.splash').removeClass('current');
			$(tabLink).addClass('current');
			$('#' + tabId).addClass('current');
		});
	}

	return {
		init: function() {
			changeTab(tabsLink);
			changeTab(nextLink);
		}
	}
}());