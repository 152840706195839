/**
 * Created by nikol on 10.10.2017..
 */
$(document).ready(function () {
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    };
    var requestParams = {
        user: getUrlParameter('user'),
        dst: getUrlParameter('dst'),
        dst2: getUrlParameter('dst2'),
        location: getUrlParameter('location'),
        password: getUrlParameter('password')
    };

    var popoverHideTimer = 1500;

    var splashHeader = $('.splashHeader');
    var splashBody = $('.splashBody');

    var splashFacebookPage = splashBody.children('.socialFacebookPage');
    var splashSocialSeparator = splashBody.children('.socialSeparator');
    var heightDiff = window.innerHeight-splashFacebookPage.position().top;
    splashSocialSeparator.height((heightDiff>0)?heightDiff:0);

    var splashSocial = splashHeader.find('.splashSocial');

    var socialLike = splashSocial.children('.btn-like');
    var socialReview = splashSocial.children('.btn-review');
    var socialFollow = splashSocial.children('.btn-follow');
    var socialRate = splashSocial.children('.btn-rate');
    var socialShare = splashSocial.children('.btn-share');
    var headerTripAdvisor = splashHeader.children('.splashTripAdvisor');
    var splashRate = splashHeader.children('.splashRate');


    var splashLogo = splashHeader.find('.row .media .splashLogo');
    var splashModal = $('#splashModal');
    var splashHeaderMedia = splashHeader.find('.row .media');

    var splashBannerYouTube = splashBody.find('.row.socialContent.socialBanner.socialBannerYoutube');

    var remoteData = {
        logoURL: '/assets/logos/point_zg.jpg',
        name: 'Vedran',
        surname: 'Jambrović',
        locationName: 'Johann Franck',
        firstLogIn: '30.2.2015.',
        visitedLocations: [['30.2.2015.','Johann Franck'], ['29.2.2015.','Johann'], ['28.2.2015.','Franck']],
        translations: {
            welcomeTo: 'Dobrodošao u',
            withUsSince: 's nama od',
            not: 'Nisi',
            visitedLocations: 'Posjećene lokacije',
            logMeOut: 'Odjavi me',
            itHappens: 'Događa se',
            logOutMessage: 'Ako se ovim uređajem koristi više ljudi ili si ga dobio moguće je da se netko drugi već prijavio na JOIN.<br><br>No, ništa strašno. Samo se odjavi i prijavi ponovo sa svojim računom.',
            close: 'Zatvori'
        },
        splashParameters: {
            socialButtons: {
                facebookLike: {
                    link: '',
                    active: false
                },
                facebookShare: {
                    link: '',
                    active: false
                },
                tripAdvisor: {
                    link: '',
                    active: false
                },
                instagram: {
                    link: '',
                    active: false
                },
                join: true
            },
            feeds: {
                facebook: {
                    link: '',
                    active: false
                },
                instagram: {
                    link: '',
                    active: false
                },
                join: {
                    active: false
                }
            },
            banners: {
                photo: {
                    link: '',
                    active: false
                },
                video: {
                    link: '',
                    active: false
                },
                text: {
                    active: false
                }
            }
        }
    };
    $.getJSON( '/splashParameters', {}).done(data =>{
        data = JSON.parse(data.SplashTemplates[0].json);
        remoteData.splashParameters = data;

        socialLike.toggle(remoteData.splashParameters.socialButtons.facebookLike.active);
        socialReview.toggle(remoteData.splashParameters.socialButtons.tripAdvisor.active);
        socialShare.toggle(remoteData.splashParameters.socialButtons.facebookShare.active);
        socialFollow.toggle(remoteData.splashParameters.socialButtons.instagram.active);
        socialRate.toggle(remoteData.splashParameters.socialButtons.joinRate);
        splashBannerYouTube.toggle(remoteData.splashParameters.banners.video.active);
        var player;

        player = new YT.Player('splashBannerYouTube', {
            height: '390',
            width: '640',
            videoId: remoteData.splashParameters.banners.video.link.split('youtube.com/watch?v=')[1]
        });

    });


    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.

    $(function () {
        $('[data-toggle="popover"]').popover()
    });

    socialLike.unbind('click').on('click', function(){
        $('[data-toggle="popover"]').each(function (key, value) {
            $(value).popover('hide');
        });
        headerTripAdvisor.hide();
        setTimeout(function(){
            socialLike.popover();
            socialLike.attr('data-content','<div><b>Već si nas lajkao</b><br>Ali hvala. Ljubavi nikad previše</div>');
            socialLike.popover('show');
            setTimeout(function () {
                socialLike.popover('hide');
            }, popoverHideTimer)
        }, 69);
    });
    socialShare.unbind('click').on('click', function(){
        $('[data-toggle="popover"]').each(function (key, value) {
            $(value).popover('hide');
        });
        headerTripAdvisor.hide();
        setTimeout(function(){
            socialShare.popover();
            socialShare.attr('data-content','<div><b>Već si se prijavio</b><br> ali drago nam je da si opet tu</div>');
            socialShare.popover('show');
            setTimeout(function () {
                socialShare.popover('hide');
            }, popoverHideTimer);
        }, 69);

    });
    socialFollow.unbind('click').on('click', function(){
        $('[data-toggle="popover"]').each(function (key, value) {
            $(value).popover('hide');
        });
        headerTripAdvisor.hide();
        setTimeout(function(){
            socialFollow.popover();
            socialFollow.attr('data-content','<div><b>Već nas pratiš</b><br>ali od viška glava ne boli</div>');
            socialFollow.popover('show');
            setTimeout(function () {
                socialFollow.popover('hide');
            }, popoverHideTimer)
        }, 69);
    });
    socialReview.on('click', function(){
        $('[data-toggle="popover"]').each(function (key, value) {
            $(value).popover('hide');
        });
        headerTripAdvisor.toggle();
    });

    socialRate.on('click', function(){
        $('[data-toggle="popover"]').each(function (key, value) {
            $(value).popover('hide');
        });
        socialRate.popover();
        socialRate.attr('data-content','<b>Već nas pratiš</b><br>ali od viška glava ne boli');
        socialRate.popover('show');
        splashRate.setStarRating(2);
    });

    //RATING

    splashRate.setStarRating = function (score, showMsg) {
        var me = this;
        var buttons = '';
        var popover = $(splashRate).siblings('.popover').find('.popover-content');
        popover.html('<div class="text-center"></div>');

        for(var i = 0; i<5; i++){
            popover.children().append(
                '<a href="#" class="btn btn-primary btn-rate btn-xs">'+
                '<div class="icomoon">'+(i<score?'&#xe93f;':'&#xe93e;')+'</div>'+
                '</a>');
        }

        var splashRateStars = $(popover).children().children();
        splashRateStars.each(function (key, value) {
            $(value).off('click').on('click', function () {
                splashRate.setStarRating(key+1, true);
            });
        });

        if(showMsg){
            setTimeout(function () {
                var text45 = 'Hvala. Drago nam je da ti se sviđa.';
                var text13 = 'Uvijek ima prostora za napredak.';
                popover.children().html(score<4?text13:text45);
                setTimeout(function () {
                    socialRate.popover('hide');
                    socialRate.popover('hide');
                }, popoverHideTimer)
            }, 500);
        }
    };

    for (var i = splashSocial.children(':visible').length; i<4; i++){
        splashSocial.append('<a href="#" class="btn btn-primary"></a>')
    }

    splashHeaderMedia.find('span.splashName').html(remoteData.name);
    splashHeaderMedia.find('span.splashLocation').html(remoteData.translations.welcomeTo + ' ' + remoteData.locationName);
    splashLogo.attr('src', remoteData.logoURL);
    splashLogo.attr('alt', remoteData.locationName);


    splashLogo.on('click', function () {
        var me = this;
        splashModal.modal();
        var splashModalHeader = splashModal.find('.modal-content .modal-header');
        splashModalHeader.find('.media-heading').html(remoteData.name + ' ' + remoteData.surname);
        splashModalHeader.find('.media-body p').html(remoteData.translations.withUsSince + ' ' + remoteData.firstLogIn);
        splashModalHeader.find('.btn.btn-default.splashNotYou').html(remoteData.translations.not + ' ' + remoteData.name + '?');
        splashModalHeader.find('.media-middle img').attr('src', remoteData.logoURL);
        splashModalHeader.find('.media-middle img').attr('alt', remoteData.name + ' ' + remoteData.surname);
        var locationsList = '<ul class="list-group">';
        $.each(remoteData.visitedLocations, function (key, value) {
            locationsList = locationsList + ('<li class="list-group-item">'+ value[0] + ' - ' + value[1] + '</li>')
        });
        locationsList = locationsList + '</ul>';
        splashModal.find('.modal-content .modal-body').html(
            '<div class="row splashNotYou" style="display: none;">' +
            '<div class="col-xs-12">' +
            '<h4>'+remoteData.translations.itHappens+'</h4>' + remoteData.translations.logOutMessage+'<br><br>' +
            '<button type="button" class="btn btn-default">' + remoteData.translations.logMeOut+'</button>' +
            '</div>' +
            '</div>' +
            '<div class="row">' +
            '<div class="col-xs-12">' +
            '<h4>'+remoteData.translations.visitedLocations+'</h4>' +
            locationsList +
            '</div>' +
            '</div>'
        );
        splashModal.find('.modal-content .modal-footer').html(
            '<button type="button" class="btn btn-default" data-dismiss="modal">'+remoteData.translations.close+'</button>'
        );
        splashModal.find('.modal-content .modal-header button.splashNotYou').on('click', function () {
            console.log('test');
            splashModal.find('.modal-content .modal-body .row.splashNotYou').css('display', 'block');
        });
    });
});


document.getElementsByClassName("fb-page")[0].setAttribute('data-height', window.innerHeight);


window.onscroll = function() {
    var button = document.getElementsByClassName("btnContinue")[0];
    if(window.scrollY>50){
        if ( button.className.match(/(?:^|\s)btn-circle(?!\S)/) ){
        }else{
            button.className += " btn-circle";
        }
        button.innerHTML = '<div class="icomoon">&#xe800;</div>';
    }else{
        if ( button.className.match(/(?:^|\s)btn-circle(?!\S)/) ){
            button.className =
                button.className.replace
                ( /(?:^|\s)btn-circle(?!\S)/g , '' )
        }
        button.innerHTML = 'nastavi';
    }
};