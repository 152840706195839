$(document).ready(function() {
	select.init();
});

var select = (function() {
	// Locations
	var user = $('.search__select-user');
	var city = $('.search__select-city');
	
	// Documents
	var month = $('.search__select-month');
	var year = $('.search__select-year');
	var type = $('.search__select-type');

	// Settings
	var language = $('.search__select-language');
    var settingsUser = $('.search__select-settings-user');

    var templates = $('#chooseTemplate');

	var selectJS = function(selectItem) {
		selectItem.select2({
			placeholder: selectItem.attr('data-placeholder'),
			minimumResultsForSearch: -1,
			allowClear: false
		});
	}

	return {
		init: function() {
			selectJS(city);
			selectJS(user);
			selectJS(month);
			selectJS(year);
			selectJS(type);
			selectJS(language);
            selectJS(settingsUser);
            selectJS(templates);

		}
	}
}());