$(document).ready(function() {
    locationListSwitch.init();

    var options = {
        valueNames: ['location__name', 'location__city', 'location__user']
    };

    var locationlist = new List('location-list', options);
    $('.search__input').on('keyup', function () {
        var searchString = $(this).val();
        locationlist.search(searchString);
    });

    $('.search__select-user').on('change', function () {
        var searchString = $(this).val();
        locationlist.search(searchString);
    });

    $('.search__select-city').on('change', function () {
        var searchString = $(this).val();
        locationlist.search(searchString);
    });
    
});

var locationListSwitch = (function() {
	var trigger = $('.toggle-view');
	var list = $('.locations__list');

	var listSwitch = function() {
		trigger.on('click', function(e) {
			e.preventDefault();

			$(this).toggleClass('toggle-view--list');
			list.toggleClass('locations__list--list');
		})
	}
	return {
		init: function function_name(argument) {
			listSwitch();
		}
	}
}());