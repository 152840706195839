
$(document).ready(function () {
    fileUpload.init();
});

var fileUpload = (function () {

    var logoUploadInput = $('#logo-upload-link');
    var imagePreview = $('#logo-preview');

    var bannerUploadInput = $('#banner-upload-link');
    var bannerPreview = $('#banner-preview');

    var imageContainer = $('.splash-image__uploaded');

    var upload = function (input, preview) {
        input.change(function (e) {
            if (input.prop("files") && input.prop("files")[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $('.file-uploaded').attr('src', e.target.result);
                }

                reader.readAsDataURL(input.prop("files")[0]);
                imageContainer.show();
            }
        });
    }

    return {
        init: function () {
            upload(logoUploadInput, imagePreview);
        }
    }
} ());