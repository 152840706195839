$(document).ready(function () {
    splashPreview.init();
});

var splashPreview = (function () {

    var backgroundInput = $("input[name='backgroundImage']");
    var backgroundDest = $('#preview-background');

    var colorInput = $("input[name='splash-color']");
    var colorDest = $('#color-dest');

    var logoInput = $("input[name='customLogos']");
    var logoDest = $('#logo-dest');

    var preview = function (source, destination, image, borderColor) {
        source.on("change", function (e) {
            var value = source.filter(":checked").val();
            if (image) {
                destination.css("background-image", 'url(' + value + ')');
            }

            if (borderColor) {
                destination.css("border-color", value);
            }
        });
    }

    return {
        init: function () {
            preview(backgroundInput, backgroundDest, true);
            preview(colorInput, colorDest, false, true);
            preview(logoInput, logoDest, true);
        }
    }
} ());